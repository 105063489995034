import PropTypes from "prop-types";
import React from "react";
import RequestClient from "~components/clients/requestClient";
import Err from "~components/error/error.jsx";
import * as analytics from "~components/helpers/analytics";
import { stateToClientData, fields } from "~components/helpers/clientDataHelper";
import { toMobile } from "~components/helpers/converter";
import config from "~components/helpers/config";
import * as Utm from "~components/helpers/utm";
import * as validator from "~components/helpers/validator";
import { Checkbox } from "~components/inputs/Checkbox";
import { Input } from "~components/inputs/Input";
import { Select } from "~components/inputs/Select";
import { Regions } from "~components/models/region";
import { InsurType } from "./model/insurType";
import Success from "./success";

const input = {
  id: null,
  name: null,
  value: null,
  error: null,
};

const client = new RequestClient(config.tzReqApi);

export default class InsurRequest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sending: false,
      complete: false,
      firstName: { ...input, name: "firstName", id: "firstName" },
      lastName: { ...input, name: "lastName", id: "lastName" },
      secondName: { ...input, name: "secondName", id: "secondName" },
      region: { ...input, name: "region", id: "region" },
      type: { ...input, name: "type", id: "type" },
      phone: { ...input, name: "phone", id: "phone" },
      agree: { ...input, name: "agree", id: "agree", value: undefined },
      email: { ...input, name: "email", id: "email" },
    };
    this.form = React.createRef();
  }

  submit = (e) => {
    if (e) e.preventDefault();
    const form = this.form.current;

    const error = validator.isValidForm(form);
    const errKey = validator.firstError(error);
    if (errKey) {
      validator.scrollTo(form, errKey);
      this.setState({
        ...error,
      });
      return false;
    }

    this.setState({ sending: true });
    const utm = Utm.parseFromCookie();
    const data = stateToClientData(this.state, utm);
    client.insurCreate(data).then(() => {
      this.setState({ sending: false, complete: true });
      analytics.sendYaEvents(this.props.yaTrackers, "send_req_insur");
    });
  };

  componentDidMount() {
    Utm.setCookie(Utm.parseFromString());
  }

  onBlur = (name, newValue, error) => {
    const valueTrimmed = newValue && newValue.trim();
    const obj = Object.assign(this.state);
    obj[name].value = valueTrimmed;
    obj[name].error = error;
    this.setState(obj);
  };

  onChange = (name, value, error) => {
    const obj = Object.assign(this.state);
    obj[name].value = value;
    obj[name].error = error;
    this.setState(obj);
  };

  render() {
    if (this.state.complete) return <Success />;
    return (
      <noindex is="xxx">
        <div
          id="calc-content"
          className="wrapper"
          style={{ paddingBottom: "1px", marginTop: "10px" }}
        >
          <Err>
            <section>
              <form action="#" method="post" ref={this.form}>
                <div className="block">
                  <Input
                    {...this.state.phone}
                    onChange={this.onChange}
                    onBlur={this.onBlur}
                    type="tel"
                    required="required"
                    pattern={fields.mobile.pattern}
                    placeholder={fields.mobile.placeholder}
                    title={fields.mobile.title}
                    maxLength="15"
                    converter={toMobile}
                    errorMessage="Укажите мобильный телефон"
                    label="Номер телефона"
                  />{" "}
                  <Select
                    {...this.state.region}
                    variants={Regions}
                    onChange={this.onChange}
                    errorMessage="Выберите регион проживания"
                    label="Регион проживания"
                  />{" "}
                  <Select
                    {...this.state.type}
                    variants={InsurType}
                    onChange={this.onChange}
                    errorMessage="Выберите тип страховки"
                    label="Тип страховки"
                  />{" "}
                </div>

                <div className="block">
                  <Input
                    {...this.state.lastName}
                    onChange={this.onChange}
                    onBlur={this.onBlur}
                    type="text"
                    required="required"
                    pattern="^[а-яА-ЯёЁ\- ]+$"
                    title="Фамилия русскими буквами"
                    maxLength="25"
                    errorMessage="Укажите фамилию"
                    label="Фамилия"
                  />{" "}
                  <Input
                    {...this.state.firstName}
                    onChange={this.onChange}
                    onBlur={this.onBlur}
                    type="text"
                    required="required"
                    pattern="^[а-яА-ЯёЁ\- ]+$"
                    maxLength="25"
                    title="Имя русскими буквами"
                    errorMessage="Укажите имя"
                    label="Имя"
                  />{" "}
                  <Input
                    {...this.state.secondName}
                    onChange={this.onChange}
                    onBlur={this.onBlur}
                    type="text"
                    required="required"
                    pattern="^[а-яА-ЯёЁ\- ]+$"
                    title="Отчество русскими буквами"
                    maxLength="25"
                    errorMessage="Укажите отчество"
                    label="Отчество"
                  />
                </div>
                <div className="block">
                  <Input
                    {...this.state.email}
                    onChange={this.onChange}
                    onBlur={this.onBlur}
                    type="text"
                    pattern="^[a-zA-Z0-9_\-\.]+@[a-zA-Z0-9_\-\.]+$"
                    title="name@domain"
                    maxLength="60"
                    errorMessage="Укажите email"
                    label="Email адрес"
                  />
                </div>
                <p>Я даю согласие:</p>
                <Checkbox {...this.state.agree} onChange={this.onChange} required="required">
                  На обработку персональных данных
                </Checkbox>
                <div id="buttons" className="steps">
                  <button
                    disabled={this.state.sending}
                    className="button fixed"
                    onClick={(e) => {
                      this.submit(e);
                    }}
                  >
                    <span>Отправить</span>
                  </button>
                </div>
              </form>
            </section>
          </Err>
        </div>
      </noindex>
    );
  }
}

InsurRequest.propTypes = {
  yaTrackers: PropTypes.array,
};
