/* eslint-disable jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/control-has-associated-label */
import React from "react";
import "./chatbot.css";
import { ChatBotClient } from "~components/clients/chatBotClient";
import config from "~components/helpers/config";
import * as Utm from "~components/helpers/utm";
const client = new ChatBotClient(config);

export default class ChatBot extends React.Component {
  constructor(props) {
    super(props);
    const hiMessage = {
      id: null,
      text: "🖐️ Привет, вы общаетесь с чат-ботом",
      type: "bot",
      sugges: [],
      raiting: -1,
      time: new Date().toLocaleTimeString(),
    };

    const host = typeof window !== "undefined" ? window.location.hostname : "";

    this.state = {
      messages: [hiMessage],
      windowHiden: true,
      userMsg: "",
      utm: null,
      wait: false,
      host: host,
      raiting: [1, 2, 3, 4, 5],
    };
    this.messagesRef = React.createRef();
  }

  handleRaiting = (ind, val) => {
    let messages = Object.assign(this.state.messages);
    const msg = messages[ind];
    msg.raiting = val;
    this.setState({ messages: messages });
    client.setRating(msg.id, msg.raiting).then(() => {});
  };

  handleBtnShow = () => {
    this.setState({ windowHiden: !this.state.windowHiden }, () => {
      this.toLastMessage();
    });
  };

  handleClose = () => {
    this.setState({ windowHiden: true });
  };

  handleInputChange = (e) => {
    this.setState({ userMsg: e.target.value });
  };

  toLastMessage = () => {
    if (this.messagesRef?.current)
      this.messagesRef.current.scrollTop = this.messagesRef.current.scrollHeight;
  };

  handleSugges = (e, txt) => {
    this.handleUserMessage(e, txt);
  };

  handleUserMessage = (e, userMsg) => {
    if (e) e.preventDefault();

    const msg = userMsg || this.state.userMsg;

    if (this.state.wait || !msg || msg === "") return;

    const time = new Date().toLocaleTimeString();
    const messages = this.state.messages.concat([
      {
        text: msg,
        time: time,
        type: "user",
      },
    ]);
    this.setState({ messages: messages }, () => {
      this.setState({ userMsg: "" });
    });

    this.setState({ wait: true });
    client
      .getRespose(msg, this.state.host, this.props.userBeh.getCurUserState(), this.state.utm)
      .then((data) => {
        const time = new Date().toLocaleTimeString();
        const messages = this.state.messages.concat([
          {
            id: data.id,
            text: data.ok
              ? decodeURIComponent(escape(window.atob(data.message)))
              : "Кажется произошла ошибка :(",
            time: time,
            sugges: data.sugges || [],
            type: "bot",
            raiting: -1,
          },
        ]);
        this.setState({ wait: false });
        this.setState({ messages: messages }, () => {
          this.toLastMessage();
        });
      });
  };

  componentDidMount() {
    const utm = Utm.parseFromCookie();
    this.setState({ utm: utm });
  }

  render() {
    return (
      <noindex is="xxx">
        <div className="chatbot">
          <div role="button" className="chatbot-btn" onClick={this.handleBtnShow} tabIndex={0}>
            <span role="img" aria-label="msg">
              💬
            </span>
          </div>
          {this.state.windowHiden ? (
            <></>
          ) : (
            <>
              <div className="chatbot-window">
                <button onClick={this.handleClose} className="close-btn" tabIndex={0}>
                  ×
                </button>
                <div className="chatbot-header">
                  <p>
                    Привет,{" "}
                    <span role="img" aria-label="robot">
                      🤖
                    </span>{" "}
                    я чат-бот
                  </p>
                  <p>Помогу ответить на Ваши вопросы</p>
                </div>
                <div className="chatbot-messages" id="chat-messages" ref={this.messagesRef}>
                  {this.state.messages.map((item, index) => {
                    return (
                      <div key={index} className={`message ${item.type}`}>
                        {item.type === "bot" ? (
                          <>
                            <div
                              className="txt"
                              dangerouslySetInnerHTML={{
                                __html: `🤖 ${item.text}`,
                              }}
                            ></div>
                            {item.id ? (
                              <>
                                <div>
                                  <p className="raiting-txt">Пожалуйста оцените мой ответ</p>
                                  {this.state.raiting.map((i) => {
                                    return (
                                      <>
                                        <span
                                          key={i}
                                          className="raiting-btn"
                                          onClick={() => {
                                            this.handleRaiting(index, i);
                                          }}
                                        >
                                          {item.raiting < i ? <span>☆</span> : <span>★</span>}
                                        </span>
                                      </>
                                    );
                                  })}
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                            <div className="sugges-list">
                              {item.sugges.length > 0 && (
                                <>
                                  <br></br>
                                  <p className="center">
                                    <strong>Похоже вопросы</strong>
                                  </p>
                                </>
                              )}
                              {item.sugges.map((sItem, sIndex) => {
                                return (
                                  <span
                                    key={sIndex}
                                    className="sugges"
                                    onClick={(e) => this.handleSugges(e, sItem)}
                                  >
                                    <span role="img" aria-label="">
                                      👉
                                    </span>{" "}
                                    {sItem}
                                  </span>
                                );
                              })}
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="txt">
                              <span role="img" aria-label="">
                                🧑‍
                              </span>{" "}
                              {item.text}
                            </div>
                          </>
                        )}
                        <div className="time">{item.time}</div>
                      </div>
                    );
                  })}
                </div>
                <div className="input-container">
                  <form onSubmit={this.handleUserMessage}>
                    <input
                      spellCheck="true"
                      className="input"
                      type="text"
                      required
                      placeholder="Введите сообщение"
                      value={this.state.userMsg}
                      onChange={this.handleInputChange}
                      minLength="3"
                      maxLength="255"
                      pattern="[А-Яа-яёa-zA-Z0-9- .?!@_^%+]+"
                      title=""
                    ></input>
                    <button title="Отправить" className="send-btn" type="submit">
                      <span className="send-icon"></span>
                    </button>
                  </form>
                </div>
              </div>
            </>
          )}
        </div>
      </noindex>
    );
  }
}
