export const Regions = [{
    "id": 77,
    "value": "Москва г"
}, {
    "id": 50,
    "value": "Московская обл"
}, {
    "id": 78,
    "value": "Санкт-Петербург г"
}, {
    "id": 47,
    "value": "Ленинградская обл"
}, {
    "id": 1,
    "value": "Адыгея Респ"
}, {
    "id": 4,
    "value": "Алтай Респ"
}, {
    "id": 22,
    "value": "Алтайский край"
}, {
    "id": 28,
    "value": "Амурская обл"
}, {
    "id": 29,
    "value": "Архангельская обл"
}, {
    "id": 30,
    "value": "Астраханская обл"
}, {
    "id": 99,
    "value": "Байконур г"
}, {
    "id": 2,
    "value": "Башкортостан Респ"
}, {
    "id": 31,
    "value": "Белгородская обл"
}, {
    "id": 32,
    "value": "Брянская обл"
}, {
    "id": 3,
    "value": "Бурятия Респ"
}, {
    "id": 33,
    "value": "Владимирская обл"
}, {
    "id": 34,
    "value": "Волгоградская обл"
}, {
    "id": 35,
    "value": "Вологодская обл"
}, {
    "id": 36,
    "value": "Воронежская обл"
}, {
    "id": 5,
    "value": "Дагестан Респ"
}, {
    "id": 79,
    "value": "Еврейская АО"
}, {
    "id": 75,
    "value": "Забайкальский край"
}, {
    "id": 37,
    "value": "Ивановская обл"
}, {
    "id": 6,
    "value": "Ингушетия Респ"
}, {
    "id": 38,
    "value": "Иркутская обл"
}, {
    "id": 7,
    "value": "Кабардино-Балкарская Респ"
}, {
    "id": 39,
    "value": "Калининградская обл"
}, {
    "id": 8,
    "value": "Калмыкия Респ"
}, {
    "id": 40,
    "value": "Калужская обл"
}, {
    "id": 41,
    "value": "Камчатский край"
}, {
    "id": 9,
    "value": "Карачаево-Черкесская Респ"
}, {
    "id": 10,
    "value": "Карелия Респ"
}, {
    "id": 42,
    "value": "Кемеровская обл"
}, {
    "id": 43,
    "value": "Кировская обл"
}, {
    "id": 11,
    "value": "Коми Респ"
}, {
    "id": 44,
    "value": "Костромская обл"
}, {
    "id": 23,
    "value": "Краснодарский край"
}, {
    "id": 24,
    "value": "Красноярский край"
}, {
    "id": 91,
    "value": "Крым Респ"
}, {
    "id": 45,
    "value": "Курганская обл"
}, {
    "id": 46,
    "value": "Курская обл"
}, {
    "id": 48,
    "value": "Липецкая обл"
}, {
    "id": 49,
    "value": "Магаданская обл"
}, {
    "id": 12,
    "value": "Марий Эл Респ"
}, {
    "id": 13,
    "value": "Мордовия Респ"
}, {
    "id": 51,
    "value": "Мурманская обл"
}, {
    "id": 83,
    "value": "Ненецкий АО"
}, {
    "id": 52,
    "value": "Нижегородская обл"
}, {
    "id": 53,
    "value": "Новгородская обл"
}, {
    "id": 54,
    "value": "Новосибирская обл"
}, {
    "id": 55,
    "value": "Омская обл"
}, {
    "id": 56,
    "value": "Оренбургская обл"
}, {
    "id": 57,
    "value": "Орловская обл"
}, {
    "id": 58,
    "value": "Пензенская обл"
}, {
    "id": 59,
    "value": "Пермский край"
}, {
    "id": 25,
    "value": "Приморский край"
}, {
    "id": 60,
    "value": "Псковская обл"
}, {
    "id": 61,
    "value": "Ростовская обл"
}, {
    "id": 62,
    "value": "Рязанская обл"
}, {
    "id": 63,
    "value": "Самарская обл"
}, {
    "id": 64,
    "value": "Саратовская обл"
}, {
    "id": 14,
    "value": "Саха /Якутия/ Респ"
}, {
    "id": 65,
    "value": "Сахалинская обл"
}, {
    "id": 66,
    "value": "Свердловская обл"
}, {
    "id": 92,
    "value": "Севастополь г"
}, {
    "id": 15,
    "value": "Северная Осетия - Алания Респ"
}, {
    "id": 67,
    "value": "Смоленская обл"
}, {
    "id": 26,
    "value": "Ставропольский край"
}, {
    "id": 68,
    "value": "Тамбовская обл"
}, {
    "id": 16,
    "value": "Татарстан Респ"
}, {
    "id": 69,
    "value": "Тверская обл"
}, {
    "id": 70,
    "value": "Томская обл"
}, {
    "id": 71,
    "value": "Тульская обл"
}, {
    "id": 17,
    "value": "Тыва Респ"
}, {
    "id": 72,
    "value": "Тюменская обл"
}, {
    "id": 18,
    "value": "Удмуртская Респ"
}, {
    "id": 73,
    "value": "Ульяновская обл"
}, {
    "id": 27,
    "value": "Хабаровский край"
}, {
    "id": 19,
    "value": "Хакасия Респ"
}, {
    "id": 86,
    "value": "Ханты-Мансийский Автономный округ - Югра АО"
}, {
    "id": 74,
    "value": "Челябинская обл"
}, {
    "id": 20,
    "value": "Чеченская Респ"
}, {
    "id": 21,
    "value": "Чувашия Респ"
}, {
    "id": 87,
    "value": "Чукотский АО"
}, {
    "id": 89,
    "value": "Ямало-Ненецкий АО"
}, {
    "id": 76,
    "value": "Ярославская обл"
}];