/* eslint-disable no-useless-constructor, jsx-a11y/control-has-associated-label */
import React from "react";
import PropTypes from "prop-types";
import * as validator from "~components/helpers/validator.js";

export class Checkbox extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  onChange = (e) => {
    const name = e.target.name;
    const value = validator.getRealValue(e.target, this.props.value);
    this.props.onChange(name, value, undefined);
  };

  render() {
    return (
      <div className="checkbox">
        <input
          id={this.props.id}
          name={this.props.name}
          type="checkbox"
          required={this.props.required}
          checked={this.props.value !== undefined}
          className={this.props.error ? "invalid" : ""}
          data-msg={this.props.errorMessage}
          onChange={this.onChange}
        />
        {this.props.error && (
          <span data-for={this.props.name} className="invalid-msg">
            {this.props.error}
          </span>
        )}
        <label htmlFor={this.props.name}>{this.props.children}</label>
      </div>
    );
  }
}

Checkbox.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string || PropTypes.number,
  required: PropTypes.string || PropTypes.bool,
  error: PropTypes.string,
  errorMessage: PropTypes.string,
  index: PropTypes.number,
  onChange: PropTypes.func,
};
