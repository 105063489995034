import React from "react";
import "react-multi-carousel/lib/styles.css";
import AdvStartup from "~components/adv/advStartup";
import ChatBot from "~components/chatBot/chatBot";
import InsurRequest from "~components/insurRequest/insurRequest";
import Layout from "../components/layout";
import PageList from "~components/pageList/pageList";
import Questionnare from "~components/questionnare/questionnare";
import Request from "../components/request/request";
import Seo from "../components/seo";
import Text from "~components/text/text";
import {
  getLinksByKeyStartWith,
  getModules,
  getQuestionnareByKeyStartWith,
  getSnippetContentByKey,
  prepareText,
} from "~components/helpers/pageHelper";
import * as Utm from "~components/helpers/utm";
import LocalStore from "../request/storages/LocalStore";
import UserBehaviorStore from "../storages/userBehaviorStore";

const Fragment = React.Fragment;
const userBeh = new UserBehaviorStore();
const localStore = new LocalStore();

const PageTpl = (props) => {
  const siteName = props.pageContext.siteName;

  Utm.setCookie(Utm.parseFromString());
  if (typeof window !== "undefined") {
    userBeh.init();
    localStore.setSiteName(siteName);
  }

  const conf = { siteName: siteName };
  const title = props.pageContext.title;
  const description = props.pageContext.description;
  const h1 = props.pageContext.h1;
  const htmlContent = prepareText(props.pageContext.htmlContent, conf);
  const isCatalog = props.pageContext.isCatalog;
  const subPages = props.pageContext.subPages;
  const subCatalogs = props.pageContext.subCatalogs;

  const footerHtml = prepareText(
    getSnippetContentByKey(props.pageContext.txtSnippets, "footer"),
    conf
  );
  const advNameReqFail = props.pageContext.config.adv_name_req_fail; // рекламный набор при отказе в заёме
  const advName = props.pageContext.config.adv_name; // рекламный набор по умолчанию

  const pageSnippets = props.pageContext.pageSnippets || [];
  const filteredlinkSnippets = props.pageContext.linkSnippets.filter((i) => {
    return pageSnippets.some((some) => some === i.Id);
  });

  const topMenus = getLinksByKeyStartWith(filteredlinkSnippets, "top_menu");
  const bottomMenus = getLinksByKeyStartWith(filteredlinkSnippets, "bottom_menu");
  const trackers = props.pageContext.trackers;
  const yaTrackers = trackers.filter((i) => i.Type === 0).map((i) => i.Id);

  let canonical = `https://${siteName}${props.pageContext.route}/`;
  if (props.pageContext.route === "/") canonical = null;
  const isIndex = props.pageContext.route === "/";
  if (isIndex) canonical = null;

  const modules = getModules(htmlContent);

  return (
    <Layout
      footerHtml={footerHtml}
      title={siteName}
      topMenus={topMenus}
      bottomMenus={bottomMenus}
      trackers={trackers}
      pixels={props.pageContext.pixels}
    >
      <Seo title={title} description={description} canonical={canonical} />
      <div className="bg-gray">
        <div className="container">
          <h1>{h1}</h1>
          {modules.map((i, ind) => {
            let m;
            if (i.name === "text") m = <Text text={i.text}></Text>;
            else if (i.name === "calc") {
              const questFail = getQuestionnareByKeyStartWith(
                props.pageContext.questionnaireSnippets,
                "questionnare_fail"
              );
              m = (
                <Request
                  advNameFail={advNameReqFail}
                  yaTrackers={yaTrackers}
                  userBeh={userBeh}
                  questTrackerId="yaCounter82252396"
                  questFail={questFail && questFail[0]}
                />
              );
            } else if (i.name === "insur_req") m = <InsurRequest yaTrackers={yaTrackers} />;
            else if (i.name === "questionnare") {
              const questData = getQuestionnareByKeyStartWith(
                props.pageContext.questionnaireSnippets,
                i.id
              );
              m = <Questionnare data={questData && questData[0]} yaTrackers={yaTrackers} />;
            } else if (i.name === "adv") {
              m = <AdvStartup name={i.adv_name || advName} yaTrackers={yaTrackers} />;
            } else if (i.name === "adv_news")
              m = (
                <AdvStartup
                  id="advnews1"
                  type="news"
                  addClass="_30"
                  caption="Новости"
                  name={i.adv_name || advName}
                />
              );
            else if (i.name === "chatbot") m = <ChatBot userBeh={userBeh} />;
            return <Fragment key={ind}>{m}</Fragment>;
          })}
          {isCatalog && <PageList pages={subPages} catalogs={subCatalogs} />}
        </div>
      </div>
    </Layout>
  );
};
export default PageTpl;
