/* eslint-disable no-useless-constructor, jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/control-has-associated-label */
import React from "react";
import PropTypes from "prop-types";
import * as validator from "~components/helpers/validator.js";
import "./input.css";

export class Input extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  onBlur = (e) => {
    const name = e.target.name;
    const value = validator.getRealValue(e.target, this.props.value);
    const error = validator.getInvalidMsg(e.target);
    const newValue = !error && this.props.converter ? this.props.converter(value) : value;

    this.props.onBlur(name, newValue, error, this.props.variants);
  };

  onChange = (e) => {
    const name = e.target.name;
    const value = validator.getRealValue(e.target, this.props.value);

    this.props.onChange(name, value, undefined, []);
  };

  onClick = (e) => {
    const value = e.target.getAttribute("value");
    const name = e.target.getAttribute("inputname");

    this.props.onBlur(name, value, undefined, []);
  };

  render() {
    return (
      <div className={this.props.placeholder ? "input masked" : "input"}>
        <label htmlFor={this.props.name} className={this.props.bold ? "bold" : ""}>
          {this.props.label}
        </label>
        <input
          id={this.props.id}
          name={this.props.name}
          value={this.props.value || ""}
          type={this.props.type}
          required={this.props.required}
          pattern={this.props.pattern}
          title={this.props.title}
          min={this.props.min}
          max={this.props.max}
          step={this.props.step}
          maxLength={this.props.maxLength}
          placeholder={this.props.placeholder}
          className={this.props.ok ? "valid" : this.props.error ? "invalid" : ""}
          data-msg={this.props.errorMessage}
          onChange={this.onChange}
          onBlur={this.onBlur}
        />
        {this.props.addRange && (
          <div className="input-range-block">
            <input type="range"></input>
          </div>
        )}
        {this.props.error && <span className="invalid-msg">{this.props.error}</span>}
        {this.props.ok && <span className="ok-msg">{this.props.ok}</span>}
      </div>
    );
  }
}

Input.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  same: PropTypes.bool,
  type: PropTypes.string,
  required: PropTypes.string,
  pattern: PropTypes.string,
  title: PropTypes.string,
  min: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  max: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  step: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxLength: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.string,
  error: PropTypes.string,
  errorMessage: PropTypes.string,
  ok: PropTypes.oneOfType([PropTypes.string, PropTypes.any]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  variants: PropTypes.array,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  converter: PropTypes.func,
  addRange: PropTypes.bool,
  rangeMin: PropTypes.number,
  rangeMax: PropTypes.number,
  rangeSte: PropTypes.number,
};
