function checkStatusCode(response) {
  if (response.status !== 200) throw response;
  return response;
}

export class ChatBotClient {
  constructor(config) {
    this.config = config;
  }

  getRespose(message, host, userState, utm) {
    return new Promise((resolve) => {
      fetch(`${this.config.chatBot.url}message`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          host: host,
          message: message,
          userState,
          utmSource: utm?.utm_source || "",
          UtmWmSubChannel: utm?.origin_wmid || "",
          utmClickId: utm?.click_id || "",
          utmWmId: utm?.prx || "",
        }),
      })
        .then(checkStatusCode)
        .then((response) => response.json())
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          console.error(error);
          return { ok: false };
        });
    });
  }

  setRating(id, raiting) {
    return new Promise((resolve) => {
      fetch(`${this.config.chatBot.url}setRaiting`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({ id, raiting }),
      })
        .then(checkStatusCode)
        .then((response) => response.json())
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          console.error(error);
          return { ok: false };
        });
    });
  }
}
