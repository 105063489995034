/* eslint-disable no-useless-constructor, jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/no-onchange, jsx-a11y/control-has-associated-label */
import React from "react";
import PropTypes from "prop-types";
import * as validator from "~components/helpers/validator.js";

export class Select extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  onChange = (e) => {
    let name = e.target.name;
    let value = validator.getRealValue(e.target, this.props.value);
    this.props.onChange(name, value, undefined, this.props.same);
  };

  render() {
    return (
      <div className={`input ${this.props.className ? this.props.className : ""}`}>
        <label htmlFor={this.props.name}>{this.props.label}</label>
        <select
          id={this.props.id}
          name={this.props.name}
          value={this.props.value || ""}
          required="required"
          className={(this.props.value ? "active" : "") + (this.props.error ? " invalid" : "")}
          data-msg={this.props.errorMessage}
          onChange={this.onChange}
        >
          {<option disabled="disabled" value=""></option>}
          {this.props.variants.map((region) => (
            <option value={region.id} key={region.id}>
              {region.value}
            </option>
          ))}
        </select>
        {this.props.error && (
          <span data-for={this.props.name} className="invalid-msg">
            {this.props.error}
          </span>
        )}
      </div>
    );
  }
}

Select.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string || PropTypes.number,
  same: PropTypes.bool,
  className: PropTypes.string,
  error: PropTypes.string,
  errorMessage: PropTypes.string,
  label: PropTypes.string,
  variants: PropTypes.array,
  onChange: PropTypes.func,
  autoFocus: PropTypes.bool,
};
