import React from "react";
import "./error.css";

export default class Error extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  reload = () => {
    if (typeof window !== "undefined") window.location.reload();
  };

  componentDidCatch(error) {
    this.setState({ error: error });
  }

  render() {
    if (this.state.error) {
      return (
        <div className="error-container">
          <div>
            <p>Рассмотрение заявки задерживается/либо ошибка</p>
            <p>Обновите страницу</p>
          </div>
          <br />
          <button onClick={() => this.reload()} className="button">
            Обновить
          </button>
        </div>
      );
    }
    return this.props.children;
  }
}
