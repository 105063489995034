import React from "react";
import * as analytics from "../helpers/analytics";
import "./questionnare.css";

export default class Questionnare extends React.Component {
  constructor(props) {
    super(props);
    this.state = { questions: [] };
  }

  onClick(question, answer) {
    analytics.sendYaEvents(this.props.yaTrackers, "questionnare_answer", {
      question: question,
      answer: { answer: answer },
    });

    const items = Object.assign(this.state.questions);
    items.push(question);
    this.setState({ questions: items });
  }

  render() {
    const items = this.props.data?.items;
    return (
      <div className="questionnare-container">
        <p className="questionnare-caption">{this.state.caption}</p>
        {items &&
          items.map((i) => {
            return (
              <div key={i.question}>
                {this.state.questions.indexOf(i.question) > -1 ? (
                  <p>Спасибо за ваш отзыв!</p>
                ) : (
                  <div className="questionnare-question-container">
                    <p className="questionnare-question">{i.question}</p>
                    <div className="questionnare-answer-container">
                      {i.answers.map((a) => {
                        return (
                          <div key={i.question + a}>
                            <input
                              onClick={() => this.onClick(i.question, a)}
                              id={i.question + a}
                              type="radio"
                              value={a}
                              name={i.question}
                            ></input>{" "}
                            <label htmlFor={i.question + a}>{a}</label>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </div>
            );
          })}
      </div>
    );
  }
}

Questionnare.propTypes = {};
