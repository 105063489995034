/* eslint-disable no-useless-constructor */
import React from "react";
import "./style.css";

export default class Success extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.hideItems = document.querySelectorAll("h1, main div:not(#status)");
  }

  componentDidMount() {
    this.hideItems.forEach((item) => {
      item.classList.add("none");
    });
  }

  render() {
    return (
      <div id="status">
        <p style={{ textAlign: "center" }}>Спасибо за Вашу заявку!</p>
        <p style={{ textAlign: "center" }}>Наш оператор свяжется с Вами в ближайшее время.</p>
      </div>
    );
  }
}
