import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import "./pageList.css";

export class PageList extends React.Component {
  constructor(props) {
    super(props);
    this.state = { route: "", hoverClass: "" };
  }

  mouseEnter(route) {
    this.setState({ route: route, hoverClass: "hover" });
  }

  mouseLeave() {
    this.setState({ hoverClass: "" });
  }

  render() {
    return (
      <>
        {this.props.catalogs?.length > 0 && (
          <ul className="crumbs">
            {this.props.catalogs.map((i) => {
              const item = i;
              if (item.name && item.name !== "") {
                const route = `${item.route}/`;
                return (
                  <li key={item.route}>
                    <Link to={route}>{item.name}</Link>
                  </li>
                );
              } else return <></>;
            })}
          </ul>
        )}

        {this.props.pages?.length > 0 && (
          <div className="page-list">
            {this.props.pages.map((i) => {
              const item = i;
              if (item.name && item.name !== "") {
                const route = `${item.route}/`;
                return (
                  <div
                    role="button"
                    tabIndex={0}
                    key={route}
                    className={`page-list-item ${
                      route === this.state.route && this.state.hoverClass
                    }`}
                    onMouseEnter={() => this.mouseEnter(route)}
                    onMouseLeave={() => this.mouseLeave()}
                  >
                    <div className="page-list-item-content">
                      <div className="page-list-item-img-fade"></div>
                      <div className="page-list-item-img-container">
                        <Link to={route} className="page-list-item-link-img"></Link>
                        <div
                          className="page-list-item-img-container-img"
                          style={{ backgroundImage: `url(${item.imgId})` }}
                        />
                      </div>
                      <div className="page-list-item-text-container">
                        <div className="page-list-item-text-container-caption">
                          <Link to={route} className="page-list-item-link">
                            {item.name}
                          </Link>
                        </div>
                        <div className="page-list-item-text-container-text">{item.descr}</div>
                      </div>
                    </div>
                    <div className="page-list-item-line"></div>
                  </div>
                );
              } else return <></>;
            })}
          </div>
        )}
      </>
    );
  }
}

PageList.propTypes = {
  pages: PropTypes.array,
  catalogs: PropTypes.array,
};

PageList.defaultProps = {
  pages: [],
  catalogs: [],
};

export default PageList;
