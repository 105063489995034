import PropTypes from "prop-types";
import React from "react";

export default class Text extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  render() {
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: this.props.text,
        }}
      ></div>
    );
  }
}

Text.propTypes = {
  text: PropTypes.string,
};
