export const fields = {
  mobile: {
    pattern: "^\\+?(7|8)?\\s?9[\\d]{2}\\s?[\\d]{3}\\s?[\\d]{2}\\s?[\\d]{2}$",
    title: "9XX XXX XX XX",
    placeholder: "9XX XXX XX XX",
  },
};

export function stateToClientData(state, utm) {
  const data = {};
  for (const item in state) data[state[item].name] = state[item].value;
  data.utm = {
    source: utm.utm_source,
    prx: utm.prx,
    clickid: utm.click_id,
    site: window.location.hostname,
  };
  return data;
}
